import { get } from 'svelte/store';
import { useCookiesStore } from '$lib/utils/cookie-store';
import { browser } from '$app/environment';

const cookiesStore = useCookiesStore();
const cookies = get(cookiesStore.cookies);

export class Monitor {
  private static OFFSET_TOP = 0;
  private static SIZE_THRESHOLD = 0;
  private static TIME_THRESHOLD = 0;
  private static TIME_INTERVAL = 1;

  private static _initializedConfig = false;

  private element: HTMLElement;
  private onViewCallback: () => any;

  private visibleTime: number = 0;
  private observer: IntersectionObserver;
  private interval: number;

  constructor(element: HTMLElement, onViewElement: () => any) {
    if (browser) {
      Monitor.initializeConfiguration();
      this.element = element;
      this.onViewCallback = onViewElement;

      this.checkInitialVisibility(); // Check if the element is already visible at the start
    }
  }

  /**
   * Start monitoring the element
   */
  startMonitoring() {
    if (browser) {
      this.initializeObserver();
    }
  }

  /**
   * Stop monitoring the element
   */
  stopMonitoring() {
    if (browser) {
      this.observer.unobserve(this.element);
    }
  }

  /**
   * Check if the element is currently visible in the viewport
   * @returns {boolean} True if the element is visible, false otherwise
   */
  isElementVisible() {
    const rect = this.element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Check if the element is already visible at the start
   */
  private checkInitialVisibility() {
    const rect = this.element.getBoundingClientRect();
    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      // Element is already visible
      this.onViewCallback();
    }
  }

  /**
   * Initialize intersection obeserver and start observing
   */
  private initializeObserver() {
    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: `-${Monitor.OFFSET_TOP}px 0px 0px 0px`,
      threshold: [0.0, Monitor.SIZE_THRESHOLD],
    };

    this.observer = new IntersectionObserver(this.callback.bind(this), observerOptions);
    this.observer.observe(this.element);
  }

  /**
   * The callback function for the intersection observer
   * @param entries The entries of the intersection observer observed
   */
  private callback(entries: IntersectionObserverEntry[]) {
    const entry = entries[0];
    if (entry.isIntersecting) {
      if (entry.intersectionRatio >= Monitor.SIZE_THRESHOLD) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    } else {
      this.stopTimer();
    }
  }

  /**
   * Start timer by setting an interval. Start timer executes
   * the onViewElement callback function when the TIME_THRESHOLD is reached.
   */
  private startTimer() {
    this.interval = window.setInterval(() => {
      this.visibleTime += Monitor.TIME_INTERVAL;
      if (this.visibleTime >= Monitor.TIME_THRESHOLD) {
        this.onViewCallback();
        this.stopTimer();
        this.resetTimer();
      }
    }, Monitor.TIME_INTERVAL * 1000);
  }

  /**
   * Set current timer to 0
   */
  private resetTimer() {
    this.visibleTime = 0;
  }

  /**
   * Stop timer by clearing interval
   */
  private stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Initiliaze the configuration for the monitor. The configuration
   * is common among monitors so this is a static function
   */
  private static initializeConfiguration() {
    if (!Monitor._initializedConfig) {
      const navbarHeight = parseFloat(getComputedStyle(document.body).getPropertyValue('--navbar-height'));

      let stickyFiltersHeight = 0;
      const stickyFilters = document.querySelector('.sticky-filters');
      if (stickyFilters) {
        stickyFiltersHeight = stickyFilters.clientHeight;
      }

      Monitor.OFFSET_TOP = navbarHeight + stickyFiltersHeight;
      Monitor.SIZE_THRESHOLD = cookies.cookie_ab_monitor === 'A' ? 0.5 : 0.75; // Percentage of element that must be visible
      Monitor.TIME_THRESHOLD = cookies.cookie_ab_monitor === 'A' ? 2 : 2; // Number of seconds that the element must be visible
      Monitor._initializedConfig = true;
    }
  }
}
