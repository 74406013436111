const invert = (obj: { [key: string]: string }): { [key: string]: string } => Object.keys(obj).reduce((res, k) => Object.assign(res, { [obj[k]]: k }), {});

let slugShortenerMap = {};

const slugShortenerMapReverse = invert(slugShortenerMap);


export const hasShortSlug = (realSlug: string): boolean => {
    return slugShortenerMap[realSlug] !== undefined;
}

export const isShortSlug = (shortSlug: string): boolean => {
    return slugShortenerMapReverse[shortSlug] !== undefined;
}


export const getFullSlugFromShorted = (shortSlug: string): string => {
    return slugShortenerMapReverse[shortSlug] ? slugShortenerMapReverse[shortSlug] : shortSlug;
}

export const getShortedSlugFromFull = (realSlug: string): string => {
    return slugShortenerMap[realSlug] ? slugShortenerMap[realSlug] : realSlug;
}