import { checkWebpSupport } from '$lib/utils/webp-utils';
import { get } from 'svelte/store';
import { useCookiesStore } from '$lib/utils/cookie-store';
import type { Asset } from 'contentful';
import { getAssetsConfig } from '$lib/utils/db-utils';
import { configureURL } from '@24hbs/utils';
import { browser } from '$app/environment';
import { PUBLIC_ALTERNATE_STATIC_ASSETS_FORCE_TO_WEBP, PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL } from '$env/static/public';

const cookiesStore = useCookiesStore();
const cookies = cookiesStore ? get(cookiesStore.cookies) : undefined;

function isMangledImage(url) {
  const extensionRegex = /.(jpg\.webp|jpeg\.webp|png\.webp|gif\.webp)$/;
  return extensionRegex.test(url);
}

function isNotWebpImage(url) {
  const extensionRegex = /.(jpg|jpeg|png|gif)$/;
  return extensionRegex.test(url);
}

function removeWebpExtension(url: string) {
  return url.replace(/\.(webp)$/gi, '');
}

function rebaseToAlternateUrl(url: string) {
  const alternateStaticAssetsForceToWebp =
    PUBLIC_ALTERNATE_STATIC_ASSETS_FORCE_TO_WEBP === 'true';

  if (PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL) {
    const originalUrl = new URL(url);
    const alternateUrl = new URL(
      PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL
    );
    alternateUrl.pathname = originalUrl.pathname;
    url = alternateUrl.toString();
  }

  // if flag to force to webp on alternate is set force to webp
  if (alternateStaticAssetsForceToWebp && isNotWebpImage(url)) {
    return url + '.webp';
  }

  return url;
}

export function handleUrlClientSide(url) {
  if (browser) {
    const webpSupport = checkWebpSupport();

    let finalUrl = url;
    if (cookies.cookie_static_assets === 'alternate') {
      // rebase to alternate CDN if on alternate CDN
      finalUrl = rebaseToAlternateUrl(url);
    } else if (cookies.cookie_static_assets === 'original') {
      // rollback to not mangled version if not on alternate CDN
      finalUrl = isMangledImage(url) ? removeWebpExtension(url) : url;
    }

    // if we doesn't support WEBP just revert to not mangled version
    if (!webpSupport && isMangledImage(finalUrl)) {
      return removeWebpExtension(finalUrl);
    }

    return finalUrl;
  }

  return url;
}

/**
 * Get the URL of a contentful asset rebasing it on base static assets path configured.
 * 
 * @param asset The contentful asset.
 * 
 * @returns The URL or null if source asset is not set
 */
export function getURLFromContentfulAsset(asset: Asset, preview: boolean = false): string {
  if (preview) {
    return asset && asset.fields.file.url ? asset.fields.file.url : null;
  }
  const assetsConfig = getAssetsConfig();
  const sourceUrl = asset &&
    asset.fields.file.url
    ? asset.fields.file.url
    : null;

  if (assetsConfig.staticAssetsBaseUrl && sourceUrl) {
    return configureURL(assetsConfig.staticAssetsBaseUrl, sourceUrl.startsWith("http") ? sourceUrl : `https:${sourceUrl}`, assetsConfig.forceToWebp);
  }

  return sourceUrl;
}
