import { getBrowserInfo } from '$lib/utils/browser-utils';

/**
 * Checks WEBP support
 * @param feature The type of WEBP images supported (lossy, lossless, animation, alpha), defaults to lossy
 * @returns True if the feature is supported, false if not
 */
export const checkWebpSupport = (
    feature: 'lossy' | 'lossless' | 'alpha' | 'animation' = 'lossy'
): boolean => {
    const value = localStorage.getItem('webpsupport');
    if (value) {
        return Boolean(value);
    } else {
        const info = getBrowserInfo();
        const v = !(info.browserName == 'Safari' && info.version < 14);
        localStorage.setItem('websupport', JSON.stringify(v));
        return v;
    }
};
