export enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export const MONTHS = [
  { text: 'Gennaio', value: Month.January },
  { text: 'Febbraio', value: Month.February },
  { text: 'Marzo', value: Month.March },
  { text: 'Aprile', value: Month.April },
  { text: 'Maggio', value: Month.May },
  { text: 'Giugno', value: Month.June },
  { text: 'Luglio', value: Month.July },
  { text: 'Agosto', value: Month.August },
  { text: 'Settembre', value: Month.September },
  { text: 'Ottobre', value: Month.October },
  { text: 'Novembre', value: Month.November },
  { text: 'Dicembre', value: Month.December },
];

const monthDays = {
  January: 31,
  February: new Date(new Date().getFullYear(), 1, 29).getMonth() == 1 ? 29 : 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

export const getDaysOfMonth = (month: Month): number => {
  return monthDays[Month[month]];
};

export const getDateOfMonth = (date: 'start' | 'end', month: Month, year?: number): string => {
  const currentYear = new Date().getFullYear();
  let newDate = new Date();

  newDate.setFullYear(year || currentYear);
  newDate.setDate(1);
  newDate.setMonth(month - 1);

  if (date === 'start') {
    newDate.setUTCHours(0, 0, 0, 0);
  } else {
    newDate.setDate(getDaysOfMonth(month));
    newDate.setUTCHours(23, 59, 59, 0);
  }
  return newDate.toISOString();
};

export const getNext12MonthsRanges = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const currentYearMonths = MONTHS.slice(currentMonth).map((month) => ({
    text: `${month.text} ${currentYear}`,
    value: `${month.value}-${currentYear}`,
  }));

  // These are already passed month so they are going to be in the next year
  const nextYearMonths = MONTHS.slice(0, currentMonth).map((month) => ({
    text: `${month.text} ${currentYear + 1}`,
    value: `${month.value}-${currentYear + 1}`,
  }));

  return [...currentYearMonths, ...nextYearMonths];
};

export const getLast12MonthsRanges = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const currentYearMonths = MONTHS.slice(0, currentMonth + 1).map((month) => ({
    text: `${month.text} ${currentYear}`,
    value: `${month.value}-${currentYear}`,
  }));

  // These are already passed month so they are going to be in the last year
  const lastYearMonths = MONTHS.slice(currentMonth).map((month) => ({
    text: `${month.text} ${currentYear - 1}`,
    value: `${month.value}-${currentYear - 1}`,
  }));

  return [...currentYearMonths.reverse(), ...lastYearMonths.reverse()];
};

export const getMonthRange = (date: string): [string, string] => {
  const [month, year] = date.split('-');
  const startDate = getDateOfMonth('start', Number(month), Number(year));
  const endDate = getDateOfMonth('end', Number(month), Number(year));

  return [startDate, endDate];
}

export const isTodayBetweenTwoDates = (dateStr1: string, dateStr2: string): boolean => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Ottenere la data corrente
  const currentDate = new Date();

  // Confrontare le date
  if (currentDate >= date1 && currentDate <= date2) {
    return true;
  }

  return false;
}